import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';

import { Layout } from '../components/Layout';

const PostWrapper = styled.div``;

export default ({ data }) => {
  return (
    <Layout>
      {data.allMdx.nodes.map(({ id, excerpt, frontmatter, fields }) => (
        <PostWrapper key={id}>
          <Link to={fields.slug}>
            <h3>{frontmatter.title}</h3>
          </Link>
        </PostWrapper>
      ))}
    </Layout>
  );
};

export const query = graphql`
  query COFFEE_INDEX_QUERY {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true }, posttype: { in: "coffee" } } }
    ) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          date
          title
        }
        fields {
          slug
        }
      }
    }
  }
`;
